export const THREEKIT_ENV: string="dev-1482";
export const THREEKIT_ASSET_ID="5430b04a-c04b-48b2-999d-f88cc0ad3b37";
export const THREEKIT_ID_DATA_TABLE_PRICE="839f647d-27af-421c-acf1-f43ea904151a";
export const API_URL="/api";

export const THREEKIT_DEV_ORG_ID="4143413b-7021-4606-a80c-0331517065eb";
export const THREEKIT_DEV_PUBLIC_TOKEN="9f7d838c-953b-47c2-a096-48008fa2b825";

export const THREEKIT_PREVIEW_ORG_ID="5e0ae8c5-65ae-48ab-b22c-c85fd46b3163";
export const THREEKIT_PREVIEW_PUBLIC_TOKEN="6f2596ac-77b4-46fa-a321-99c19ad7340d";

export const THREEKIT_ADMIN_FTS_ORG_ID="a41e78dd-a855-439b-8784-e5b75e7bf9af";
export const THREEKIT_ADMIN_FTS_PUBLIC_TOKEN="29c18232-9faa-4de2-a05f-a5b4da724c72";

const REACT_APP_THREEKIT_DEV_URL="https://dev-1482.threekit.dev";
const REACT_APP_THREEKIT_DEV_API="https://dev-1482.threekit.dev/api";

const REACT_APP_THREEKIT_PREVIEW_URL="https://preview.threekit.com";
const REACT_APP_THREEKIT_PREVIEW_API="https://preview.threekit.com/api";

const REACT_APP_THREEKIT_ADMIN_FTS_URL="https://admin-fts.threekit.com";
const REACT_APP_THREEKIT_ADMIN_FTS_API="https://admin-fts.threekit.com/api";

const REACT_APP_THREEKIT_PREVIEW_IMAGES_URL="https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com";
const REACT_APP_THREEKIT_ADMIN_FTS_IMAGES_URL="https://admin-fts.threekit.com/api/images/webp/power2-4096/preview.threekit.com";

const IS_PREVIEW=THREEKIT_ENV === "preview";
const IS_ADMIN_FTS=THREEKIT_ENV === "admin-fts";
const IS_DEV=!IS_PREVIEW&&!IS_ADMIN_FTS;

export const THREEKIT_ORG_ID=
  IS_ADMIN_FTS ? THREEKIT_ADMIN_FTS_ORG_ID :
  IS_PREVIEW ? THREEKIT_PREVIEW_ORG_ID :
  THREEKIT_DEV_ORG_ID;

export const THREEKIT_PUBLIC_TOKEN=
  IS_ADMIN_FTS ? THREEKIT_ADMIN_FTS_PUBLIC_TOKEN :
  IS_PREVIEW ? THREEKIT_PREVIEW_PUBLIC_TOKEN :
  THREEKIT_DEV_PUBLIC_TOKEN;

export const THREEKIT_URL=
  IS_ADMIN_FTS ? `https://admin-fts.threekit.com` :
  IS_PREVIEW ? `https://preview.threekit.com` :
  `https://dev-1482.threekit.dev`;
export const THREEKIT_URL_API=`${THREEKIT_URL}${API_URL}`;
export const THREEKIT_IMAGES_URL=`${THREEKIT_URL}/api/images/webp/power2-4096/preview.threekit.com`;
