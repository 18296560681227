import { ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinetsBase/size";
import { checkDefaultPositionCabinetsWall } from "../cabinetsWall/position";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinetsWall/size";
import { isUpperPantryCabinetWall } from "../checkModels";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import { getConfiguratorModelFromNullName } from "./decorativePanel";

export const enum ATTRIBUTES_COUNTERTOP {
  COUNTERTOP = "Counter Top",
}
export type CountertopValuesT = "no" | "yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_COUNTERTOP.COUNTERTOP]?: CountertopValuesT,
}

const isEqualAxisSize = (
  firstAxisSize: number,
  lastAxisSize: number,
): boolean => Math.abs(firstAxisSize - lastAxisSize) < 0.1;

const checkUpperPantryAboveCabinetBase = (
  nullNameCabinetBase: ModelsName_NodesT
): boolean => {

  const posCabinetBase = getTranslationThreekit({name: nullNameCabinetBase});
  const sizeCabinetBase = getSizeModelBoxFromAssetCabinetBase(nullNameCabinetBase);
  const completedCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);

  let resultValue = false;

  completedCabinetsWall.forEach((nullNameCabinetWall) => {

    if (resultValue || !isUpperPantryCabinetWall(nullNameCabinetWall)) return;

    const posCabinetWall = getTranslationThreekit({name: nullNameCabinetWall});
    const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(nullNameCabinetWall);

    const isEqualCoords = isEqualCoordsTolerance(
      { ...posCabinetBase, y: 0 },
      { ...posCabinetWall, y: 0 },
      0.1
    );

    const isUperPantryDefaultPosY = checkDefaultPositionCabinetsWall(nullNameCabinetWall);

    const isEqualSizeXZ = isEqualAxisSize(sizeCabinetBase["x"], sizeCabinetWall["x"]) && isEqualAxisSize(sizeCabinetBase["z"], sizeCabinetWall["z"]);

    if (
      isEqualCoords &&
      isUperPantryDefaultPosY &&
      isEqualSizeXZ
    ) resultValue = true;

  })

  return resultValue;

}

export const checkCountertopCabinetsBase = () => {

  const completedCabinetsBase = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);

  completedCabinetsBase.forEach((nullNameCabinetBase) => {

    const isUpperPantryAboveCabinetBase = checkUpperPantryAboveCabinetBase(nullNameCabinetBase);
    const configuratorCabinetBase = getConfiguratorModelFromNullName(nullNameCabinetBase);

    if (isUpperPantryAboveCabinetBase) {

      configuratorCabinetBase.setConfiguration({
        [ATTRIBUTES_COUNTERTOP.COUNTERTOP]: "no",
      });

    } else {

      configuratorCabinetBase.setConfiguration({
        [ATTRIBUTES_COUNTERTOP.COUNTERTOP]: "yes",
      });

    }

  })

}