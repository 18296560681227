import {
  IConfiguration,
  IConfigurationArray,
} from "@threekit-tools/treble/dist/types";
import {
  ArrSummaryFinishing,
  ObjSummaryFinishing,
  SummaryFinishingInfo,
} from "../../store/selectors/summarySelectors";
import {
  getFullConfigurationAttribute,
  getNodeFromId,
} from "../../utils/threekit/general/getFunctions";
import { ATTRIBUTES_NAMES_THREEKIT } from "./../../utils/constants/attributesThreekit";
import { ProductsOther } from "./../../utils/constants/cabinets";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
// import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
// import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import {
  ArrSummaryPriceDataTableT,
  ObjSummaryCabinetsT,
} from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { ATTRIBUTES_DECORATIVE_PANELS } from "../cabinets/configuration/decorativePanel";
import { THREEKIT_IMAGES_URL } from "../../utils/threekit/threekitConfig/threekitConfig";

const ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER =
  "1107";
const ID_ISLAND_BACK__DECO_SIDE_PANEL_12 = "1101";
const ID_ISLAND_BACK__DECO_SIDE_PANEL_18 = "1102";
const ID_ISLAND_BACK__DECO_SIDE_PANEL_24 = "LWSCLICK-69";

export const getProductDecorativePanelCabinetsBase = () => {
  return ProductsOther.find(
    (objProduct) =>
      objProduct["id"] ===
      ID_DECORATIVE_PANEL_CABINETS_BASE_OR_CABINETS_ISLAND_OR_DISHWASHER
  );
};

export const getSummaryDecorativePanelCabinetsBase = (
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsBase = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
  ) as IConfigurationArray;
  const fullConfigurationAppliances = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.APPLIANCES
  ) as IConfigurationArray;

  let objSummaryDecorativePanelCabinetsBase: ObjSummaryCabinetsT = {};
  [...fullConfigurationCabinetsBase, ...fullConfigurationAppliances].forEach((attributeFullValue) => {
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    if (
      !!configuration &&
      !!configuration["Decorative"] &&
      configuration["Decorative"] === "Yes"
    ) {
      const decorativeLocation = configuration["Decorative Location"];
      const id = ID_ISLAND_BACK__DECO_SIDE_PANEL_24;

      let count = 1;
      if (decorativeLocation === "Left and Right") count = 2;

      if (objSummaryDecorativePanelCabinetsBase.hasOwnProperty(id)) {
        objSummaryDecorativePanelCabinetsBase[id]["count"] =
          objSummaryDecorativePanelCabinetsBase[id]["count"] + count;
        return;
      }

      const productInfoFromDataTable = priceDatatable.find(
        (objProduct) =>
          objProduct["ItemNumber"] === id &&
          // objProduct["Framed"] === convertedFrameValue &&
          objProduct["Color"] === convertedColorValue
      );

      if (!!productInfoFromDataTable) {
        return (objSummaryDecorativePanelCabinetsBase[id] = {
          ...productInfoFromDataTable,
          thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
          count: count,
        });
      }

    }
  });

  return Object.values(objSummaryDecorativePanelCabinetsBase);
};

export const getSummaryDecorativePanelCabinetsIsland = (
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsIsland = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND
  ) as IConfigurationArray;

  let objSummaryDecorativePanel: ObjSummaryCabinetsT = {};
  fullConfigurationCabinetsIsland.forEach((attributeFullValue) => {
    const assetId = attributeFullValue["assetId"];
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    if (
      !!configuration &&
      !!configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] &&
      configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] === "Yes"
    ) {
      const decorativeLocation =
        configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION];
      const id = ID_ISLAND_BACK__DECO_SIDE_PANEL_24;
      // const label = productDecorativePanelCabinetsBase["label"];
      let countSidePanels = 1;
      if (decorativeLocation === "Left and Right") countSidePanels = 2;

      if (objSummaryDecorativePanel.hasOwnProperty(id)) {
        objSummaryDecorativePanel[id]["count"] =
          objSummaryDecorativePanel[id]["count"] + countSidePanels;
      } else {
        const productInfoFromDataTable = priceDatatable.find(
          (objProduct) =>
            objProduct["ItemNumber"] === id &&
            // objProduct["Framed"] === convertedFrameValue &&
            objProduct["Color"] === convertedColorValue
        );

        if (!!productInfoFromDataTable) {
          objSummaryDecorativePanel[id] = {
            ...productInfoFromDataTable,
            thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
            count: countSidePanels,
          };
        }
      }

      const itemNаme = getNodeFromId(assetId)["name"];

      let idBackDecorativePanel = "";
      if (!!itemNаme) {
        if (itemNаme.includes("12in")) {
          idBackDecorativePanel = ID_ISLAND_BACK__DECO_SIDE_PANEL_12;
        } else if (itemNаme.includes("18in")) {
          idBackDecorativePanel = ID_ISLAND_BACK__DECO_SIDE_PANEL_18;
        } else if (itemNаme.includes("24in")) {
          idBackDecorativePanel = ID_ISLAND_BACK__DECO_SIDE_PANEL_24;
        }
      }

      if (idBackDecorativePanel.length > 0) {
        if (objSummaryDecorativePanel.hasOwnProperty(idBackDecorativePanel)) {
          objSummaryDecorativePanel[idBackDecorativePanel]["count"] =
            ++objSummaryDecorativePanel[idBackDecorativePanel]["count"];
        } else {
          const productInfoFromDataTable = priceDatatable.find(
            (objProduct) =>
              objProduct["ItemNumber"] === idBackDecorativePanel &&
              // objProduct["Framed"] === convertedFrameValue &&
              objProduct["Color"] === convertedColorValue
          );

          if (!!productInfoFromDataTable) {
            objSummaryDecorativePanel[idBackDecorativePanel] = {
              ...productInfoFromDataTable,
              thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
              count: 1,
            };
          }
        }
      }

    }
  });

  return Object.values(objSummaryDecorativePanel);
};

export const mergeSummaryArrays = (
  array: ArrSummaryFinishing
): ArrSummaryFinishing => {
  const resultArray: ArrSummaryFinishing = [];
  const cloneArray = array.slice(0);
  cloneArray.forEach((obj) => {
    const foundObj = resultArray.find((item) => item.id === obj.id);
    if (foundObj) {
      foundObj.count += obj.count;
    } else {
      resultArray.push(obj);
    }
  });

  return resultArray;
};
