import { createSelector } from "@reduxjs/toolkit";
import { getPositionAndRotationForAllCabinets } from "../../functionsConfigurator/cabinets/position";
import {
  getSummaryDecorativePanelCabinetsBase,
  getSummaryDecorativePanelCabinetsIsland,
} from "../../functionsConfigurator/summaryInfo/getSummaryDecorativePanel";
import { getSummaryFillerStrip } from "../../functionsConfigurator/summaryInfo/getSummaryFillerStrip";
import { getSummaryMoulding } from "../../functionsConfigurator/summaryInfo/getSummaryMoulding";
import { getSummaryToeKick } from "../../functionsConfigurator/summaryInfo/getSummaryToeKick";
import { PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";
import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import store, { RootState } from "../store";
import {
  ID_PARAMETER_LOWER_MOULDING_IN_UI,
  ID_PARAMETER_UPPER_MOULDING_IN_UI,
  ID_PARAMETER_UPPER_MOULDING_SIZI_IN_UI,
  getPriceDataTable,
  // getValueFrameOption,
  getValueOptionCabinetsBase,
  getValueOptionCabinetsIsland,
  getValueOptionCabinetsWall,
  getValueOptionColorCabinetsBase,
  getValueOptionColorCabinetsIsland,
  getValueOptionColorCabinetsWall,
  getValueOptionDecorativeEndCabinetsBase,
  getValueOptionDecorativeEndCabinetsIsland,
  getValueOptionLowerMolding,
  getValueOptionUpperMolding,
  getValueOptionUpperMoldingSize,
} from "./settingsUISelectors";
import {
  ArrSummaryPriceDataTableT,
  getArrSummaryProductsForCabinets,
  mergeArrSummaryProducts,
} from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { getSummaryFridgePanels } from "../../functionsConfigurator/summaryInfo/getSummaryFridgePanels";
import { getSummaryFillerStripOTR } from "../../functionsConfigurator/summaryInfo/getSummaryFillerStripOTR";
import { isNumeric } from "../../utils/other/numberData";

export type ValueOptionAllCabinetsT = {
  [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: string[];
  [PRODUCT_POSITIONS_KEYS.WALL_CABINET]: string[];
  [PRODUCT_POSITIONS_KEYS.ISLAND_CABINET]: string[];
};
export const getValueOptionAllCabinets = (
  state: RootState
): ValueOptionAllCabinetsT => {
  return {
    [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: getValueOptionCabinetsBase(state),
    [PRODUCT_POSITIONS_KEYS.WALL_CABINET]: getValueOptionCabinetsWall(state),
    [PRODUCT_POSITIONS_KEYS.ISLAND_CABINET]:
      getValueOptionCabinetsIsland(state),
  };
};

export type ValueOptionDecorativeEndAllCabinetsT = {
  [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: boolean;
  [PRODUCT_POSITIONS_KEYS.ISLAND_CABINET]: boolean;
};
export const getValueOptionDecorativeEndAllCabinets = (
  state: RootState
): ValueOptionDecorativeEndAllCabinetsT => {
  return {
    [PRODUCT_POSITIONS_KEYS.BASE_CABINET]:
      getValueOptionDecorativeEndCabinetsBase(state),
    [PRODUCT_POSITIONS_KEYS.ISLAND_CABINET]:
      getValueOptionDecorativeEndCabinetsIsland(state),
  };
};

export type ObjMouldingInfoT = {
  [ID_PARAMETER_UPPER_MOULDING_IN_UI]: boolean;
  [ID_PARAMETER_LOWER_MOULDING_IN_UI]: boolean;
  [ID_PARAMETER_UPPER_MOULDING_SIZI_IN_UI]: string;
};
export const getObjMouldingInfo = (state: RootState): ObjMouldingInfoT => {
  return {
    [ID_PARAMETER_UPPER_MOULDING_IN_UI]: getValueOptionUpperMolding(state),
    [ID_PARAMETER_LOWER_MOULDING_IN_UI]: getValueOptionLowerMolding(state),
    [ID_PARAMETER_UPPER_MOULDING_SIZI_IN_UI]:
      getValueOptionUpperMoldingSize(state),
  };
};

export const getSummaryCabinetsInfo = createSelector(
  getValueOptionAllCabinets,
  getPriceDataTable,
  (valueOptionAllCabinetsUI, priceDatatable): ArrSummaryPriceDataTableT => {
    const state = store.getState();
    // const frameCabinets = getValueFrameOption(state);

    const colorValueCabinetsBase = getValueOptionColorCabinetsBase(state);
    const arrProductsCabinetsBase = getArrSummaryProductsForCabinets(
      valueOptionAllCabinetsUI[PRODUCT_POSITIONS_KEYS.BASE_CABINET],
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsBase
    );

    const colorValueCabinetsWall = getValueOptionColorCabinetsWall(state);
    const arrProductsCabinetsWall = getArrSummaryProductsForCabinets(
      valueOptionAllCabinetsUI[PRODUCT_POSITIONS_KEYS.WALL_CABINET],
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsWall
    );

    const colorValueCabinetsIsland = getValueOptionColorCabinetsIsland(state);
    const arrProductsCabinetsIsland = getArrSummaryProductsForCabinets(
      valueOptionAllCabinetsUI[PRODUCT_POSITIONS_KEYS.ISLAND_CABINET],
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsIsland
    );

    const mergedArrProducts = mergeArrSummaryProducts([
      ...arrProductsCabinetsBase,
      ...arrProductsCabinetsIsland,
      ...arrProductsCabinetsWall,
    ]);

    return mergedArrProducts;
  }
);

export interface SummaryFinishingInfo {
  id: string;
  name: string;
  count: number;
}
export type ArrSummaryFinishing = SummaryFinishingInfo[];
export type ObjSummaryFinishing = {
  [key in string]: SummaryFinishingInfo;
};

export const getSummaryFinishingInfo = createSelector(
  getValueOptionDecorativeEndAllCabinets,
  getObjMouldingInfo,
  getPriceDataTable,
  (
    valueOptionDecorativeEndAllCabinetsUI,
    objMouldingInfo,
    priceDatatable
  ): ArrSummaryPriceDataTableT => {

    const state = store.getState();
    // const frameCabinets = getValueFrameOption(state);
    const colorValueCabinetsBase = getValueOptionColorCabinetsBase(state);
    const colorValueCabinetsIsland = getValueOptionColorCabinetsIsland(state);
    const colorValueCabinetsWall = getValueOptionColorCabinetsWall(state);

    // DecorativePanel
    // DecorativePanel for Cabinets Base
    let summaryDecorativePanelCabinetsBase: ArrSummaryPriceDataTableT = [];
    if (
      valueOptionDecorativeEndAllCabinetsUI[PRODUCT_POSITIONS_KEYS.BASE_CABINET]
    ) {
      summaryDecorativePanelCabinetsBase =
        getSummaryDecorativePanelCabinetsBase(
          priceDatatable,
          // frameCabinets,
          colorValueCabinetsBase
        );
    }

    // DecorativePanel for Cabinets Island
    let summaryDecorativePanelCabinetsIsland: ArrSummaryPriceDataTableT = [];
    if (
      valueOptionDecorativeEndAllCabinetsUI[PRODUCT_POSITIONS_KEYS.BASE_CABINET]
    ) {
      summaryDecorativePanelCabinetsIsland =
        getSummaryDecorativePanelCabinetsIsland(
          priceDatatable,
          // frameCabinets,
          colorValueCabinetsIsland
        );
    }

    // Toe Kick
    const summaryToeKick = getSummaryToeKick(
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsBase,
      colorValueCabinetsIsland,
    );

    // Mouldings
    let summaryMoulding: ArrSummaryPriceDataTableT = [];
    if (
      objMouldingInfo[ID_PARAMETER_UPPER_MOULDING_IN_UI] ||
      objMouldingInfo[ID_PARAMETER_LOWER_MOULDING_IN_UI]
    ) {
      const valueUpperMoulding = objMouldingInfo[
        ID_PARAMETER_UPPER_MOULDING_IN_UI
      ]
        ? objMouldingInfo[ID_PARAMETER_UPPER_MOULDING_SIZI_IN_UI]
        : objMouldingInfo[ID_PARAMETER_UPPER_MOULDING_IN_UI];
      summaryMoulding = getSummaryMoulding(
        priceDatatable,
        valueUpperMoulding,
        objMouldingInfo[ID_PARAMETER_LOWER_MOULDING_IN_UI],
        colorValueCabinetsWall
      );
    }

    //Filler Strip
    const summaryFillerStrip = getSummaryFillerStrip(
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsBase,
      colorValueCabinetsWall,
    );

    // Filler Strip OTR
    const summaryFillerStripOTR = getSummaryFillerStripOTR(
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsWall
    )

    // Fridge Panels
    const summaryFridgePanels = getSummaryFridgePanels(
      priceDatatable,
      // frameCabinets,
      colorValueCabinetsWall
    )

    // const spreadSummaryUI = [
      // ...summaryDecorativePanelCabinetsBase,
      // ...summaryDecorativePanelCabinetsIsland,
      // ...summaryToeKick,
      // ...summaryMoulding,
      // ...summaryFillerStrip,
    // ];

    // const arrSummaryUI = mergeSummaryArrays(spreadSummaryUI);

    // const arrSummaryFromDataTable = createArrSummaryFromDataTable(
    //   arrSummaryUI,
    //   priceDatatable
    // );

    const mergedArrFinishing = mergeArrSummaryProducts([
      ...summaryDecorativePanelCabinetsBase,
      ...summaryDecorativePanelCabinetsIsland,
      ...summaryToeKick,
      ...summaryMoulding,
      ...summaryFillerStrip,
      ...summaryFillerStripOTR,
      ...summaryFridgePanels,
    ]);

    return mergedArrFinishing;
  }
);

export const getSummaryInfoForPDF = createSelector(
  getSummaryCabinetsInfo,
  getSummaryFinishingInfo,
  (summaryCabinetsInfo, summaryFinishingInfo): ArrSummaryPriceDataTableT => {
    const summaryInfoForPDF = [
      ...summaryCabinetsInfo,
      ...summaryFinishingInfo,
    ].map((objSummaryProductInfo, indx: number) => {
      return {
        ...objSummaryProductInfo,
        index: indx + 1,
      };
    });
    return summaryInfoForPDF;
  }
);

export const getTotalPrice = createSelector(
  getSummaryCabinetsInfo,
  getSummaryFinishingInfo,
  (summaryCabinetsInfo, summaryFinishingInfo): number => {
    const totalPrice = [...summaryCabinetsInfo, ...summaryFinishingInfo].reduce(
      (accTotalPrice: number, objSummaryProductInfo) => {

        if (!isNumeric(objSummaryProductInfo["MSRP"])) return accTotalPrice;

        const productPrice =
          Number(objSummaryProductInfo["MSRP"]) *
          objSummaryProductInfo["count"];

        return (accTotalPrice += productPrice);
        
      },
      0
    );

    return Number(totalPrice.toFixed(2));
  }
);

export const getPositionAndRotationAllCabinets = (state: RootState) => {
  const positionAllCabinetsBase = getPositionAndRotationForAllCabinets(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  const positionAllCabinetsIsland = getPositionAndRotationForAllCabinets(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const positionAllCabinetsWall = getPositionAndRotationForAllCabinets(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );
  const positionAllAppliances = getPositionAndRotationForAllCabinets(
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  return {
    ...positionAllCabinetsBase,
    ...positionAllCabinetsIsland,
    ...positionAllCabinetsWall,
    ...positionAllAppliances,
  };
};
