import { ArrWallRangesT, WallRangeT, addIntervalToArrIntervals } from "./getIntervalsOnWallForCabinetsWall";

/**
 * Перевіряє чи пустий інтервал (empty = true).
 *
 * @param {WallRangeT} objInterval Об'єкт інтервалу.
 * @return {boolean} true - інтервал пустий, false - інтервал заповнений.
 */
export const isIntervalEmpty = (
  objInterval: WallRangeT,
): boolean => objInterval["empty"];

/**
 * Перевіряє чи присутне поле "name" в інтервалі.
 *
 * @param {WallRangeT} objInterval Об'єкт інтервалу.
 * @return {boolean} true - поле "name" в інтервалі присутне, false - поле "name" в інтервалі відсутне.
 */
export const isIntervalName = (
  objInterval: WallRangeT,
): boolean => objInterval.hasOwnProperty("name");

/**
 * Повертає розмір інтервалу.
 *
 * @param {WallRangeT} objInterval Об'єкт інтервалу.
 * @return {number} Розмір інтервалу.
 */
export const getIntervalSize = (
  objInterval: WallRangeT,
): number => {
  return objInterval["range"][1] - objInterval["range"][0];
}

/**
 * Перевіряє чи підходить розмір пустого інтервалу для встановлення в нього моделі.
 *
 * @param {WallRangeT} objInterval Об'єкт інтервалу.
 * @param {number} widthModel Ширина моделі, яка має поміститись в інтервал.
 * @return {boolean} true - розмір інтервалу більший за ширину моделі, false - розмір інтервалу менший за ширину моделі.
 */
export const isIntervalCorrespondsToModelSize = (
  objInterval: WallRangeT,
  widthModel: number
): boolean => {
  const sizeInterval = objInterval["range"][1] - objInterval["range"][0];
  return sizeInterval >= widthModel;
}

/**
 * Перевіряє чи підходить інтервал для встановлення в нього нової моделі.
 *
 * @param {WallRangeT} objInterval Об'єкт інтервалу.
 * @param {number} widthModel Ширина моделі, яка має поміститись в інтервал.
 * @return {boolean} true - інтервал підходить, false - інтервал не підходить.
 */
export const checkIntervalForModel = (
  objInterval: WallRangeT | undefined,
  widthModel: number
): boolean => {
  if (objInterval === undefined) return false;
  return isIntervalEmpty(objInterval) && isIntervalCorrespondsToModelSize(objInterval, widthModel)
}

/**
 * Перевіряє чи входить точка в інтервал з заданою точністю.
 *
 * @param {IsPointInIntervalWithTolerancePropsI} objProps Об'єкт параметрів.
 * 
 * @param {number} point Позиція точки.
 * @param {number} startInterval Початок інтервалу в якому перевіряється точка.
 * @param {number} endInterval Кінець інтервалу в якому перевіряється точка.
 * @param {number} tolerance Точність входження точки в інтервал.
 * 
 * @return {boolean} true - точка потрапляє в інтервал, false - точка не потрапляє в інтервал.
 */
interface CheckPointInIntervalWithTolerancePropsI {
  point: number;
  startInterval: number;
  endInterval: number;
  tolerance: number;
}
export function checkPointInIntervalWithTolerance({
  point,
  startInterval,
  endInterval,
  tolerance
}: CheckPointInIntervalWithTolerancePropsI): boolean {
  return point >= (startInterval - tolerance) && point <= (endInterval + tolerance);
}

/**
 * Об'єднує інтервали.
 *
 * @param {ArrWallRangesT} arrIntervalsCabinetsWallAndFeatures Масив інтервалів з моделями, які розташовані на стандартній висоті та з Features (вікна, двері, проеми)
 * @param {ArrWallRangesT} arrIntervalsFilledFromFridge Масив інтервалів з холодильниками
 * @return {ArrWallRangesT} Об'єднаний масив інтервалів
 */
export const mergeIntervals = (
  arrIntervalsCabinetsWallAndFeatures: ArrWallRangesT,
  arrIntervalsFilledFromFridge: ArrWallRangesT
): ArrWallRangesT => {
  let intervalsCabinetsWallFeaturesFridge: ArrWallRangesT =
    arrIntervalsCabinetsWallAndFeatures;
  arrIntervalsFilledFromFridge.forEach((objIntervalFridge) => {
    intervalsCabinetsWallFeaturesFridge = addIntervalToArrIntervals(
      arrIntervalsCabinetsWallAndFeatures,
      objIntervalFridge
    );
  });
  return intervalsCabinetsWallFeaturesFridge;
};