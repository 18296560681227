import { PlaneCabinetsWallT } from "../../utils/constants/nodesNamesThreekit";
import { isNullNameAppliancesFridge } from "../cabinets/checkModels";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { getIntervalsInfoOnWallForCabinetsBase } from "./getIntervalsInfoOnWallBase";
import { ArrWallRangesT } from "./getIntervalsOnWallForCabinetsWall";

/**
 * Формує масив заповнених інтервалів на плейні, в яких розташовані холодильники
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого шукаємо наявність інтервалів з холодильниками
 * @return {ArrWallRangesT} Масив інтервалів з холодильниками на плейні
 */
export const getIntervalsFridge = (namePlane: PlaneCabinetsWallT): ArrWallRangesT => {
  const modelsBaseNullOnWall = getModelsBaseNullOnWall(
    getWallNameFromPlaneName(namePlane)
  );
  const intervalsCabinetsBaseOnWall = getIntervalsInfoOnWallForCabinetsBase(
    modelsBaseNullOnWall,
    namePlane
  );
  const arrIntervalsFilledFromFridge = intervalsCabinetsBaseOnWall.filter(
    (objInterval) => {
      const { empty, name, range } = objInterval;
      if (!empty && name !== undefined) {
        return isNullNameAppliancesFridge(name);
      }
    }
  );
  return arrIntervalsFilledFromFridge;
};