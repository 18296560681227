import React, { useEffect } from "react";
import s from "./RoomList.module.scss";
// import ScottLivingLogo from "../../assets/room-list/start-page-logo.png"
import ScottLivingLogo from "../../assets/room-list/start-page-logo_v1.png"
import WhalenClickItLogo from "../../assets/room-list/Whalen_Click_It_Logo_Blue_Black.png"
import { RadioButtonsSmall } from "../../components/RadioButtons/RadioButtonsSmall/RadioButtonsSmall";
import { PresetList } from "../../components/PresetList/PresetList";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { setAllSettingConfig } from "../../store/slices/settingsUI/settingsUISlice";
import { MeasurementUnitValues } from "../../utils/constants/settingsConfig2D";
import { loadCollisionPhysics } from "../../utils/threekit/initThreekit";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import { useSpaces, useThreekitInitStatus } from "@threekit-tools/treble/dist";
import { getMeasurement } from "../../store/selectors/settingsUISelectors";

export const RoomsList = () => {
  const dispatch = useAppDispatch();
  const hasLoaded = useThreekitInitStatus();
  const measurementValue = useAppSelector(getMeasurement);
  const dataRoomBuilder = useSpaces({
    config: {
      unit: measurementValue,
      zoomDefault: 20,
      // translateDefault: [8, 8],
      zoomSensitivity: 0.1,
    },
    attributes: {
      walls: "Walls",
      windows: "Windows",
      doors: "Doors",
      openings: "Openings",
    }
  });

  useEffect(() => {
    // loadCollisionPhysics();
    dispatch(setAllSettingConfig());

    if (hasLoaded) dataRoomBuilder.reset();
  }, [])

  return (
    <div className={s.pageWrap}>
      <div className={s.container}>
        <div className={s.titleBlock}>
          <img src={WhalenClickItLogo} alt="Scott Living Kitchen Planner" />
          <h1>Whalen Click-It Kitchen Planner</h1>
        </div>
        <RadioButtonsSmall id="MeasurementUnit" label="Select Measurement unit" values={MeasurementUnitValues} />
        <PresetList />
      </div>
    </div>
  )
}