import { IConfiguration, IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { ArrSummaryPriceDataTableT, ObjSummaryCabinetsT } from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
// import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
// import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { getFullConfigurationAttribute } from "../../utils/threekit/general/getFunctions";
import { ATTRIBUTES_DECORATIVE_PANELS } from "../cabinets/configuration/decorativePanel";
import { THREEKIT_IMAGES_URL } from "../../utils/threekit/threekitConfig/threekitConfig";

const ID_PANTRY_FRIDGE_PANELS_24X43 = "1105";
const ID_FRIDGE_PANELS_25 = "LWSCLICK-68";

export const getSummaryFridgePanels = (
  priceDatatable: ArrPriceDataTableT,
  // frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  // const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsWall = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
  ) as IConfigurationArray;

  let objSummaryFridgePanels: ObjSummaryCabinetsT = {};

  fullConfigurationCabinetsWall.forEach((attributeFullValue) => {
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    if (
      !!configuration &&
      !!configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] &&
      configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] === "Yes"
    ) {
      const decorativeLocation = configuration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION];
      const idPanels24X43 = ID_PANTRY_FRIDGE_PANELS_24X43;
      const idPanelsFridge = ID_FRIDGE_PANELS_25;

      let countPanels24X43 = 2;
      let countPanelsFridge = 1;

      if (decorativeLocation === "Left and Right") {
        countPanels24X43 = 4;
        countPanelsFridge = 2;
      }

      if (
        objSummaryFridgePanels.hasOwnProperty(idPanels24X43) ||
        objSummaryFridgePanels.hasOwnProperty(idPanelsFridge)
      ) {
        objSummaryFridgePanels[idPanels24X43]["count"] =
          objSummaryFridgePanels[idPanels24X43]["count"] + countPanelsFridge;
        objSummaryFridgePanels[idPanelsFridge]["count"] =
          objSummaryFridgePanels[idPanelsFridge]["count"] + countPanelsFridge;
        return;
      }

      const productInfoFromDataTablePanels24X43 = priceDatatable.find(
        (objProduct) =>
          objProduct["ItemNumber"] === idPanels24X43 &&
          // objProduct["Framed"] === convertedFrameValue &&
          objProduct["Color"] === convertedColorValue
      );

      const productInfoFromDataTablePanelsFridge = priceDatatable.find(
        (objProduct) =>
          objProduct["ItemNumber"] === idPanelsFridge &&
          // objProduct["Framed"] === convertedFrameValue &&
          objProduct["Color"] === convertedColorValue
      );

      if (!!productInfoFromDataTablePanels24X43) {
        objSummaryFridgePanels[idPanels24X43] = {
          ...productInfoFromDataTablePanels24X43,
          thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTablePanels24X43["thumbnail"]}`,
          count: countPanels24X43,
        };
      }

      if (!!productInfoFromDataTablePanelsFridge) {
        objSummaryFridgePanels[idPanelsFridge] = {
          ...productInfoFromDataTablePanelsFridge,
          thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTablePanelsFridge["thumbnail"]}`,
          count: countPanelsFridge,
        };
      }

    }
  });

  return Object.values(objSummaryFridgePanels);
};