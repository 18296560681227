import { CabinetsAndFeatures_NodesT, ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { getNumberNodeThreekitFromName } from "../../general";
import { getIntervalsWallCabinetsForAllWalls, getSizeModelRelativeTransform } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { findMatchingIntervals } from "../cabinetsWall/getTypeConnectingCabinetsWall";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinetsWall/size";
import { isOTRCabinetWall } from "../checkModels";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import { getConfiguratorModelFromNullName } from "./decorativePanel";

export const FILLER_STRIP_WIDTH = 0.0759968; // 2.992in
const COORDS_MATCH_ACCURACY = 0.02;

export const enum ATTRIBUTES_FILLER_STRIP {
  FILLER_STRIP = "Filler_Strip",
}
export type CountertopValuesT = "no" | "yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]?: CountertopValuesT,
}

export const updateFillerStripForCabinetsWallOTR = () => {

  const intervalsCabinetsWallForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const intervalsCabinetsBaseForAllWalls = getIntervalsBaseCabinetsForAllWalls();

  const intervalsCabinetsBaseForAllWallsKeys = Object.keys(
    intervalsCabinetsBaseForAllWalls
  ) as Array<keyof typeof intervalsCabinetsBaseForAllWalls>;

  intervalsCabinetsBaseForAllWallsKeys.forEach((wallName) => {

    const wallIntervalsCabinetsBase = intervalsCabinetsBaseForAllWalls[wallName];

    wallIntervalsCabinetsBase.forEach((objIntervalCabinetBase) => {

      if (!!objIntervalCabinetBase["name"] && objIntervalCabinetBase["name"].includes("Appliances")) {

        const rangeAppliance = objIntervalCabinetBase["range"];
        const sizeAppliance = getSizeModelRelativeTransform(objIntervalCabinetBase["name"]);
        const positionAppliance = getTranslationThreekit({name: objIntervalCabinetBase["name"]});
        const numWall = getNumberNodeThreekitFromName(wallName);
        const intervalsCabinetsWallInWall = intervalsCabinetsWallForAllWalls[`${NODES_THREEKIT.PLANE_CABINETS_WALL}${numWall}`];

        intervalsCabinetsWallInWall.forEach((objIntervalCabinetWall) => {

          const nameIntersectCabinetWall = objIntervalCabinetWall["name"];
          const rangeIntersectCabinetWall = objIntervalCabinetWall["range"];

          if (!!nameIntersectCabinetWall && isOTRCabinetWall(nameIntersectCabinetWall)) {

            const configuratorORTCabinetWall = getConfiguratorModelFromNullName(nameIntersectCabinetWall);
            const positionOTRCabinetWall = getTranslationThreekit({name: nameIntersectCabinetWall});

            if (
              // (rangeAppliance[0] <= rangeIntersectCabinetWall[1] && rangeAppliance[1] >= rangeIntersectCabinetWall[0]) ||
              // (Math.abs(rangeAppliance[0] - rangeIntersectCabinetWall[1]) <= 0.01) ||
              // (Math.abs(rangeAppliance[1] - rangeIntersectCabinetWall[0]) <= 0.01)
              Math.abs(positionAppliance["x"] - positionOTRCabinetWall["x"]) < COORDS_MATCH_ACCURACY &&
              Math.abs(positionAppliance["z"] - positionOTRCabinetWall["z"]) < COORDS_MATCH_ACCURACY
            ) {

              const sizeOTRCabinetWall = getSizeModelBoxFromAssetCabinetWall(nameIntersectCabinetWall);
              
              if (
                positionOTRCabinetWall["y"] - sizeAppliance["y"] > FILLER_STRIP_WIDTH &&
                positionOTRCabinetWall["y"] - sizeAppliance["y"] < FILLER_STRIP_WIDTH * 3
              ) {

                configuratorORTCabinetWall.setConfiguration({
                  [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "yes",
                });

              } else {

                configuratorORTCabinetWall.setConfiguration({
                  [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "no",
                });

              }

            } else {

              configuratorORTCabinetWall.setConfiguration({
                [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "no",
              });

            }

          }

        })

      }
    })
  })

}