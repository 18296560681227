import { checkPointInIntervalWithTolerance } from "./generalIntervals";
import { ArrWallRangesT, RangeT } from "./getIntervalsOnWallForCabinetsWall";

export const doIntervalsOverlap = (interval1: RangeT, interval2: RangeT, tolerance: number) => {
  return interval1[1] + tolerance >= interval2[0] && interval2[1] + tolerance >= interval1[0];
}

export const checkIntersectIntervalFromIntervals = (
  targetInterval: RangeT,
  arrIntervals: ArrWallRangesT,
): boolean => {
  let isIntersect: boolean = false;
  arrIntervals.forEach((interval) => {
    if (!interval.empty && doIntervalsOverlap(targetInterval, interval["range"], 0.005)) {
      isIntersect = true;
    }
  })
  return isIntersect;
}

export const checkPointInIntervals = (point: number, arrIntervals: ArrWallRangesT): boolean => {
  let isInclude: boolean = false;
  arrIntervals.forEach((interval) => {
    const isPointInInterval =  checkPointInIntervalWithTolerance({
      point: point,
      startInterval: interval["range"][0],
      endInterval: interval["range"][1],
      tolerance: 0.005
    });
    if (!interval.empty && isPointInInterval) {
      isInclude = true;
    }
  })
  return isInclude;
}