import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { DOORS_MAX_LENGTH } from "../../utils/constants/attributesThreekit";
import { setModalVisible } from "../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../store/slices/modalsSlice/modalsSliceT";

export const addFeatureRoomBuilder = ({
  attributeRoomBuilder,
  initialConfiguration,
  dispatch,
  addToSelectedElement
}: {
  attributeRoomBuilder: any;
  initialConfiguration: any;
  dispatch: Dispatch<AnyAction>;
  addToSelectedElement?: {
    selectedElement: any;
    featureType: string;
  }
}) => {
  const attributeMaxLength =
    attributeRoomBuilder.maxLength !== undefined ? attributeRoomBuilder.maxLength : DOORS_MAX_LENGTH;

  if (attributeRoomBuilder.value.length < attributeMaxLength) {

    if (addToSelectedElement !== undefined) {
      addToSelectedElement["selectedElement"].addFeature(addToSelectedElement["featureType"], initialConfiguration)
    } else {
      attributeRoomBuilder["values"][0].add(0, initialConfiguration);
    }

  } else {
    dispatch(
      setModalVisible({
        modalId: MODAL_KEYS.MESSAGE,
        value: true,
        message: `You cannot add more than ${attributeMaxLength} ${attributeRoomBuilder.label} to a room.`,
      })
    );
  }
};
