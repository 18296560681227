import { ICoordinates } from "@threekit-tools/treble/dist/types";
import {
  CabinetsAndFeatures_NodesT,
  ModelCabinetWallT,
  PlaneCabinetsWallT,
} from "../../utils/constants/nodesNamesThreekit";
import { getBoxWidthThreekit } from "../../utils/threekit/general/getFunctions";
import { checkDefaultPositionCabinetsWall } from "../cabinets/cabinetsWall/position";
import {
  isNullNameAppliancesFridge,
  isOTRCabinetWall,
} from "../cabinets/checkModels";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import { isFeaturesModelNullName } from "../features/general";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { getNeighborPlanes } from "../wallsAndFloor/getWallPlanesInfo";
import { getIntervalSize } from "./generalIntervals";
import {
  ArrWallRangesT,
  DEPTH_DOOR_CABINETS_WALL,
  WallRangeT,
  addIntervalToArrIntervals,
  createRangesEmptyAndFilled,
  getIntervalsCabinetWallFeaturesFridge,
  getIntervalsInfoOnWall,
} from "./getIntervalsOnWallForCabinetsWall";
import { getIntervalsInfoOnWallForCabinetsBase } from "./getIntervalsInfoOnWallBase";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinets/cabinetsWall/size";
import { isNullNameModelCabinetWallT } from "../cabinets/cabinetsWall/checkCabinetsWall";
import { isNullNameModelApliancesT } from "../cabinets/appliances/checkTypeApliances";
import { getSizeModelBoxFromAssetAppliances } from "../cabinets/appliances/size";
import { checkLeftIntervalCabinetsWall, checkRightIntervalCabinetsWall } from "./checkNeaborWallsIntervalsForCabinetsWall";

/**
 * Фільтрує масив інтервалів для настінних шкафів таким чином,
 * щоб в ньому залишились інтервали з моделями, які розташовані на стандартній висоті
 * та щоб залишились інтервали з Features (вікна, двері, проеми)
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого фільтруємо інтервали
 * @return {ArrWallRangesT} Масив інтервалів з моделями, які розташовані на стандартній висоті
 * та з Features (вікна, двері, проеми)
 */
const filterIntervalsCabinetsWallByDefaultHeightAndFeatures = (
  namePlane: PlaneCabinetsWallT
): ArrWallRangesT => {
  const intervalsCabinetsWall = getIntervalsInfoOnWall(namePlane);

  const arrIntervalsFilledByDefaultHeightAndFeatures =
    intervalsCabinetsWall.filter((objInterval) => {
      const { empty, name, range } = objInterval;
      if (!empty && name !== undefined) {
        return (
          checkDefaultPositionCabinetsWall(name) ||
          isFeaturesModelNullName(name)
        );
      }
    });

  const planeWidth = getBoxWidthThreekit({ name: namePlane });
  const arrIntervalsFilledAndEmpty = createRangesEmptyAndFilled(
    arrIntervalsFilledByDefaultHeightAndFeatures,
    planeWidth
  );

  return arrIntervalsFilledAndEmpty;
};

/**
 * Повертає розмір інтервалу, на основі розмірів моделі, яка розташована на сусідній стіні близько до кута.
 *
 * @param {CabinetsAndFeatures_NodesT | undefined} nameModel Name моделі.
 * @return {number} Розмір інтервалу.
 */
export const getSizeNewInterval = (
  nameModel: CabinetsAndFeatures_NodesT | undefined
): number => {
  let sizeNewInterval: number = 0;

  if (nameModel !== undefined && isNullNameModelApliancesT(nameModel)) {
    const sizeApplianceOnLeftPlane =
      getSizeModelBoxFromAssetAppliances(nameModel);
    sizeNewInterval = sizeApplianceOnLeftPlane["z"];
  }

  if (nameModel !== undefined && isNullNameModelCabinetWallT(nameModel)) {
    const sizeCabinetWallOnLeftPlane =
      getSizeModelBoxFromAssetCabinetWall(nameModel);
    sizeNewInterval =
      sizeCabinetWallOnLeftPlane["z"] + DEPTH_DOOR_CABINETS_WALL;
  }

  return sizeNewInterval;
};

/**
 * Формує массив інтервалів для на стіні для Cabinets Wall для визначення позиції для нового шкафа.
 * З урахуванням інтервалів на сусідніх стінах.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів для стіни без урахування розташування шкафів на сусідніх стінах бизько до спільних кутів.
 * @param {PlaneCabinetsWallT} currentPlaneName Name стіни, на якій відбувається позиціонування Cabinet Base в правому куті.
 * @param {ICoordinates} sizeCabinetBaseNew Розміри для нової моделі.
 */
const getIntervalsForPositionedNewCabinetWall = (
  baseIntervals: ArrWallRangesT,
  currentPlaneName: PlaneCabinetsWallT,
  sizeCabinetBaseNew: ICoordinates
): ArrWallRangesT => {
  let resultArrIntervals: ArrWallRangesT = baseIntervals;

  // Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах
  // Для того щоб їх не враховувати при додаванні нових шкафів в той самий кут
  // DEPTH_DOOR_CABINETS_WALL - товщина дверей для Cabinets Wall
  const offsetCorner = sizeCabinetBaseNew["z"] + DEPTH_DOOR_CABINETS_WALL;

  // 1. Отримуємо імена для сусідніх плейнів зліва та справа
  const { planeLeftName, planeRightName } = getNeighborPlanes(currentPlaneName);

  // 2. Визначаємо чи присутній на сусідній стіні зліва в спільному куті Cabinet Wall чи Холодильник.
  // Та, якщо модель в куті присутня, додаємо відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkLeftIntervalCabinetsWall(
    resultArrIntervals,
    planeLeftName,
    offsetCorner
  );

  // 3. Визначаємо чи присутній на сусідній стіні зправа в спільному куті Cabinet Base.
  // Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkRightIntervalCabinetsWall(
    resultArrIntervals,
    currentPlaneName,
    planeRightName,
    offsetCorner
  );

  return resultArrIntervals;
};

/**
 * Формує масив інтервалів для визначення позиції для нової моделі Cabinet Wall.
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого формується масив інтервалів
 * @param {ModelCabinetWallT} nullNameCabinetWallNew Null Name для нової моделі
 * @param {ICoordinates} sizeCabinetWallNew Розміри для нової моделі
 * @return {ArrWallRangesT} Масив інтервалів
 */
export const getIntervalsForCkeckNewPositionCabinetWall = (
  namePlane: PlaneCabinetsWallT,
  nullNameCabinetWallNew: ModelCabinetWallT,
  sizeCabinetWallNew: ICoordinates
): ArrWallRangesT => {

  // let resultIntervals: ArrWallRangesT = [];

  // // Якщо модель не є низьким шкафом, що може розташовуватись над холодильником
  // // То масив інтервалів буде включати інтервали для: Cabinets Wall, Features (вікна, двері, проеми) та інтервали для холодильників
  // if (!isOTRCabinetWall(nullNameCabinetWallNew)) {
  //   resultIntervals = getIntervalsCabinetWallFeaturesFridge(namePlane);
  // } else {
  //   // ---- Old Code Comment ----
  //   // // Якщо нова модель може розташовуватись над холодильником, то повертаємо тільки настінні інтервали intervalsCabinetsWallAndFeatures
  //   // resultIntervals =
  //   //   filterIntervalsCabinetsWallByDefaultHeightAndFeatures(namePlane);
  //   // ---- Old Code Comment ----

  //   // Якщо нова модель може розташовуватись над холодильником, то повертаємо тільки настінні інтервали intervalsCabinetsWallAndFeatures
  //   // Отримуємо всі інтервали для настінних шаф та для Features, які є на стіні namePlane
  //   // Для усіх шаф (на різній висоті)
  //   resultIntervals = getIntervalsInfoOnWall(namePlane);
  // }

  // Отримуємо масив інтервалів, який містить інтервали для: Cabinets Wall, Features (вікна, двері, проеми) та інтервали для холодильників
  const intervals = getIntervalsCabinetWallFeaturesFridge(namePlane);

  return getIntervalsForPositionedNewCabinetWall(
    intervals,
    namePlane,
    sizeCabinetWallNew
  );
};
