export const isNumeric = (value: string | number): boolean => {
  // Перевірка чи є значення числом
  return !isNaN(parseFloat(value as string)) && isFinite(value as number);
};

export const getToFixed = (number: number): number => {
  const numberString = (typeof number === 'string') ? number : number.toString();
  if (numberString.indexOf('e') !== -1) return parseInt(numberString.split('e')[1]) * -1;
  var separator = (1.1).toString().split('1')[1];
  var parts = numberString.split(separator);
  return parts.length > 1 ? parts[parts.length - 1].length : 0;
}

export const roundToTwo = (num: number) => {
  const roundedNum = Math.round(num * 100) / 100;
  if (roundedNum === Math.floor(roundedNum)) {
    return Math.floor(roundedNum);
  } else {
    return roundedNum;
  }
}

export function customRound(number: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}